import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import StyledButton from '../components/styledButton';

function NotFoundPage() {
  return (
    <>
      <Layout>
        <SEO title='404: Not found' />
        <div className='min-h-full flex flex-col items-center align-center justify-center'>
          <h1 className='text-5xl'>We're sorry</h1>
          <p>There's been some mistake.</p>
          <Link to='/'>
            <StyledButton>Take me home</StyledButton>
          </Link>
        </div>
      </Layout>
    </>
  );
}

export default NotFoundPage;
